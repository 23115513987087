<nav class="bg-gray-100">
    <div class="max-w-screen-lg mx-auto px-4 sm:px-6 lg:px-8">
      <div class="justify-between h-16 hidden md:flex">
        <div class="flex items-center w-full">
          <div class="flex-shrink-0 flex items-center">
            <img src="../assets/woc.svg" class="mr-10">
            <div class="relative w-2 h-2 flex mr-3">
              <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-100"></span>
              <span class="relative inline-flex rounded-full h-2 w-2 bg-green-400"></span>
            </div>
            <p class="text-base text-green-400 font-semibold ml-auto md:ml-0">All services are online</p>
          </div>
          <p class="text-base text-gray-600 font-medium ml-auto hidden md:block">Updated {{ 1232346882000 | date:"mediumTime" }}</p>
        </div>

      </div>

      <div class="justify-between h-16 flex items-center md:hidden">
        <div class="flex items-center w-full">
            <img src="../assets/woc.svg">
            <div class="relative w-2 h-2 flex mr-3 ml-auto">
              <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-100"></span>
              <span class="relative inline-flex rounded-full h-2 w-2 bg-green-400"></span>
            </div>
            <p class="text-base text-green-400 font-semibold">All services are online</p>
        </div>

      </div>
    </div>
</nav>