import { Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'bitping-status-page';
  bars;
  chartview = 'bars';
  updates = 'live';

  data;
  // readonly curve: any = shape.curveMonotoneX;
  loading = false;
  view: any[] = [320, 280];

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = true;
  showXAxisLabel = false;
  showYAxisLabel = false;
  timeline = true;

  colorScheme = {
    domain: ['#0984E3']
  };

  constructor() {
    this.bars = Array(76).fill(0).map((x,i)=>i);
  }
}
