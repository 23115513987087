import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'counterAnimation',
  pure: false
})
export class CounterPipe implements PipeTransform {
  lastUpdated: any = new Date();
  lastValue: number = 0;
  currentValue: number = 0;
  frames = [];
  progress = 0;
  updateInterval: any;
  transform(number: number): number {
    if(number!== this.lastValue){
      this.lastValue = number;
      let d = number - this.currentValue;
      let frames = [];
      for(let i=0; i<100; i++){
        frames.push(Math.round(d*i + this.currentValue*100)/100);
      }
      this.frames = [...frames, number];
      clearInterval(this.updateInterval);
      this.updateInterval = setInterval(() => {
        if(this.frames.length) {
          this.currentValue = this.frames.shift();
        } else {
          clearInterval(this.updateInterval);
        }
      }, 10);
    }
    return this.currentValue;
  }
}
