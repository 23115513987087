<footer class="max-w-screen-lg mx-auto px-4 sm:px-6 lg:px-8 bg-gray-100 -mt-10">
    <div class="w-full items-center flex">
        <div class="flex items-center">
            <p class="font-medium text-gray-800 mr-1 md:mr-3 hidden md:block">Powered By</p>
            <a href="https://bitping.com">
                <img src="../assets/logo-bitping.svg" class="h-6">
            </a>
        </div>
        <p class="ml-auto font-medium text-gray-700">© 2022 bitping.com</p>
    </div>
</footer>