<div class="bg-gray-100 min-h-screen">
  <app-navbar></app-navbar>
  <div class="max-w-screen-lg mx-auto">
    <div class="py-10">
      <main>
        <div class="max-w-full mx-auto px-2 sm:px-6 lg:px-8 space-y-6">
          <div class="w-full h-auto shadow bg-white rounded-lg overflow-hidden">
            <div class="p-4 px-5 rounded-t-lg bg-gray-50 w-full block sm:flex items-center">
              <p class="text-lg font-semibold text-gray-800 mb-2 md:mb-0">WhatsOnChain Services</p>
              <div class="sm:ml-auto flex items-center">
                <span class="flex space-x-0 bg-gray-100 p-1 px-1.5 rounded-md text-xs mr-3">
                  <button type="button" class="py-1 px-2 font-semibold rounded-md bg-white">1H</button>
                  <button type="button" class="py-1 px-2 font-semibold rounded-md">6H</button>
                  <button type="button" class="py-1 px-2 font-semibold rounded-md">1D</button>
                  <button type="button" class="py-1 px-2 font-semibold rounded-md">7D</button>
                  <button type="button" class="py-1 px-2 font-semibold rounded-md">30D</button>
                </span>
              </div>
            </div>

            <div class="border-b border-gray-100">
              <div class="p-6">
                <div class="flex w-full items-center mb-4">
                  <div class="flex items-center relative">
                    <div class="relative w-2 h-2 flex mr-3">
                      <span
                        class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-100"></span>
                      <span class="relative inline-flex rounded-full h-2 w-2 bg-green-400"></span>
                    </div>
                    <p class="text-base font-semibold text-gray-800 mr-2">Block Explorer</p>
                    <span
                      class="hidden md:inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-blue-400 bg-opacity-10 text-blue-500">Load Time</span>
                  </div>
                  <p class="text-base font-semibold text-gray-700 ml-auto">{{99.999 | counterAnimation}}%</p>
                </div>
                <div class="flex items-center space-x-0.5" *ngIf="chartview === 'bars'">
                  <div *ngFor="let bar of bars; let i = index" class="flex items-center">
                    <div class="bg-green-400 h-9 w-2.5 rounded-lg" [ngClass]="{'bg-red-400': i === 56}"></div>
                  </div>
                </div>
                <!-- <div class="flex items-center space-x-0.5 chart" *ngIf="chartview === 'chart'">
                    <timechart-custom-chart [scheme]="colorScheme", [results]="data", [gradient]="false", [curve]="curve", [xAxis]="false", [yAxis]="false", [legend]="false", [animations]="true", [timeline]="true", [tooltipDisabled]="false", [yScaleMin]="-2", [yScaleMax]="100", [trimYAxisTicks]="true", [showXAxisLabel]="false", [showYAxisLabel]="false", [autoScale]="false"></timechart-custom-chart>

                </div> -->
              </div>
            </div>
            <div class="border-b border-gray-100">
              <div class="p-6">
                <div class="flex w-full items-center mb-4">
                  <div class="flex items-center relative">
                    <div class="relative w-2 h-2 flex mr-3">
                      <span
                        class="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-400 opacity-100"></span>
                      <span class="relative inline-flex rounded-full h-2 w-2 bg-yellow-400"></span>
                    </div>
                    <p class="text-base font-semibold text-gray-800 mr-2">Node Status</p>
                    <span
                      class="hidden md:inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-blue-400 bg-opacity-10 text-blue-500">Uptime</span>
                  </div>
                  <p class="text-base font-semibold text-gray-700 ml-auto">{{99.955 | counterAnimation}}%</p>
                </div>
                <div class="flex items-center space-x-0.5" *ngIf="chartview === 'bars'">
                  <div *ngFor="let bar of bars; let i = index" class="flex items-center">
                    <div class="bg-green-400 h-9 w-2.5 rounded-lg" [ngClass]="{'bg-yellow-400': i > 53 && i < 64}"></div>
                  </div>
                </div>
                <div class="flex items-center space-x-0.5 chart" *ngIf="chartview === 'chart'">
                  <img src="../assets/chart.svg">

                </div>
              </div>
            </div>
            <div class="p-6">
              <div class="flex w-full items-center mb-4">
                <div class="flex items-center relative">
                  <div class="relative w-2 h-2 flex mr-3">
                    <span
                      class="animate-ping absolute inline-flex h-full w-full rounded-full bg-gray-300 opacity-100"></span>
                    <span class="relative inline-flex rounded-full h-2 w-2 bg-gray-300"></span>
                  </div>
                  <p class="text-base font-semibold text-gray-800 mr-2">Testnet</p>
                  <span
                    class="hidden md:inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-blue-400 bg-opacity-10 text-blue-500 mr-1">Uptime</span>
                  <span
                    class="hidden md:inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-gray-300 bg-opacity-10 text-gray-500">Planned Maintenance</span>
                </div>
                <p class="text-base font-semibold text-gray-700 ml-auto">{{99.88 | counterAnimation}}%</p>
              </div>
              <div class="flex items-center space-x-0.5" *ngIf="chartview === 'bars'">
                <div *ngFor="let bar of bars; let i = index" class="flex items-center">
                  <div class="bg-green-400 h-9 w-2.5 rounded-lg" [ngClass]="{'bg-gray-300': i > 68}"></div>
                </div>
              </div>
              <div class="flex items-center space-x-0.5 chart" *ngIf="chartview === 'chart'">
                <img src="../assets/chart2.svg">

              </div>
            </div>
          </div>
          <div class="w-full h-auto shadow bg-white rounded-lg">
            <div class="p-4 px-5 rounded-t-lg bg-gray-50 w-full block sm:flex items-center">
              <p class="text-lg font-semibold text-gray-800 mb-2">Status Updates</p>
              <div class="sm:ml-auto flex items-center">
                <span class="flex space-x-0 bg-gray-100 p-1 px-1.5 rounded-md text-xs mr-3">
                  <button type="button" class="py-1 px-2 font-semibold rounded-md bg-white" (click)="updates = 'live'" [ngClass]="{'bg-white': updates === 'live'}">Live</button>
                  <button type="button" class="py-1 px-2 font-semibold rounded-md" (click)="updates = 'history'" [ngClass]="{'bg-white': updates === 'history'}">History</button>
                </span>
              </div>
            </div>

            <div class="p-6 mb-24" *ngIf="updates === 'live'">
              <p class="text-lg text-gray-700 font-medium mb-8">Live Update</p>
              <div class="flow-root mb-8">
                <ul role="list" class="-mb-8">
                  <li>
                    <div class="relative pb-8">
                      <span class="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                      <div class="relative flex items-start space-x-3">
                        <div>
                          <div class="relative px-1">
                            <div class="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M5.636 18.364a9 9 0 010-12.728m12.728 0a9 9 0 010 12.728m-9.9-2.829a5 5 0 010-7.07m7.072 0a5 5 0 010 7.07M13 12a1 1 0 11-2 0 1 1 0 012 0z" />
                              </svg>
                            </div>
                          </div>
                        </div>
                        <div class="min-w-0 flex-1 py-0">
                          <div class="text-sm leading-8 text-gray-500">
                            <span class="mr-0.5">
                              <a href="#" class="font-medium text-gray-900">WoC</a>
                              updated status
                            </span>
                              <span class="mr-0.5">
                                <span
                                class="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-green-400 bg-opacity-10 text-green-500">Resolved</span>
                            </span>
                            <span class="whitespace-nowrap ml-2">1m ago</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="relative pb-8">
                      <span class="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                      <div class="relative flex items-start space-x-3">
                        <div class="relative">
                          <img class="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white" src="../assets/woc.png" alt="">
              
                          <span class="absolute -bottom-0.5 -right-1 bg-white rounded-tl px-0.5 py-px">
                            <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                              <path fill-rule="evenodd" d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z" clip-rule="evenodd" />
                            </svg>
                          </span>
                        </div>
                        <div class="min-w-0 flex-1">
                          <div>
                            <div class="text-sm">
                              <a href="#" class="font-medium text-gray-900">WoC</a>
                            </div>
                            <p class="mt-0.5 text-sm text-gray-500">Commented 1m ago</p>
                          </div>
                          <div class="mt-2 text-sm text-gray-700">
                            <p>Engineers have found the issue and a fix has been pushed.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div class="relative pb-8">
                      <span class="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                      <div class="relative flex items-start space-x-3">
                        <div>
                          <div class="relative px-1">
                            <div class="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M5.636 18.364a9 9 0 010-12.728m12.728 0a9 9 0 010 12.728m-9.9-2.829a5 5 0 010-7.07m7.072 0a5 5 0 010 7.07M13 12a1 1 0 11-2 0 1 1 0 012 0z" />
                              </svg>
                            </div>
                          </div>
                        </div>
                        <div class="min-w-0 flex-1 py-0">
                          <div class="text-sm leading-8 text-gray-500">
                            <span class="mr-0.5">
                              <a href="#" class="font-medium text-gray-900">WoC</a>
                              updated status
                            </span>
                            <span class="mr-0.5">
                              <span
                              class="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-red-400 bg-opacity-10 text-red-500">Outage</span>
                            </span>
                            <span class="whitespace-nowrap ml-2">2h ago</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
              
                  <li>
                    <div class="relative pb-8">
                      <div class="relative flex items-start space-x-3">
                        <div class="relative">
                          <img class="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white" src="../assets/woc.png" alt="">
              
                          <span class="absolute -bottom-0.5 -right-1 bg-white rounded-tl px-0.5 py-px">
                            <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                              <path fill-rule="evenodd" d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z" clip-rule="evenodd" />
                            </svg>
                          </span>
                        </div>
                        <div class="min-w-0 flex-1">
                          <div>
                            <div class="text-sm">
                              <a href="#" class="font-medium text-gray-900">WoC</a>
                            </div>
                            <p class="mt-0.5 text-sm text-gray-500">Commented 2h ago</p>
                          </div>
                          <div class="mt-2 text-sm text-gray-700">
                            <p>Testnet is experiencing an outage in the North American region, engineers are working around the clock to get a fix.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

            </div>

            <div class="p-6 mb-24" *ngIf="updates === 'history'"></div>


          </div>

        </div>
      </main>
    </div>
  </div>
</div>

<app-footer></app-footer>